<template>
  <div>
    <!-- 点击添加用户的的按钮 -->
    <div class="addBtn">
      <el-button type="primary" icon="el-icon-plus" @click="addBtn">
        新增用户
      </el-button>
    </div>
    <!-- 管理员的列表 -->
    <div class="listBox">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          prop="id"
          label="管理员ID"
          width="120"
        ></el-table-column>
        <el-table-column prop="userName" label="用户名"></el-table-column>
        <el-table-column prop="userName" label="角色">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.role_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="salt_phone" label="号码隐私加密" align="center">
          <el-tag
            slot-scope="scope"
            v-if="scope.row.salt_phone == 1"
            size="small"
            type="danger"
          >
            加密
          </el-tag>
          <el-tag
            slot-scope="scope"
            v-else-if="scope.row.salt_phone == 2"
            size="small"
          >
            不加密
          </el-tag>
        </el-table-column>
        <el-table-column
          prop="add_time"
          label="添加时间"
          width="200px"
        ></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editOne(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="delOne(scope.$index, scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增的模态框 -->
    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      title="新增管理员"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="登录名">
          <el-input v-model="form.userName" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input v-model="form.password" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="角色权限">
          <el-select
            v-model="form.userId"
            placeholder="请选择用户的角色"
            style="width: 300px"
            clearable
          >
            <el-option
              v-for="item in form.role_name"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="号码加密">
          <el-radio-group v-model="form.radioTag" @change="sortChange">
            <el-radio class="radio" :label="1">是</el-radio>
            <el-radio class="radio" :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="form.xingming" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort" style="width: 150px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiable = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisiable: false,
      form: {
        userId: null,
        password: null,
        userName: null,
        role_name: null,
        xingming: null,
        phone: null,
        sort: null,
        radioTag: 1 //1加密  2 不加密
      },
      addOredit: "", //模态框提交的【增加：add,修改：edit】
      userId: ""
    }
  },
  methods: {
    addBtn() {
      this.dialogVisiable = !this.dialogVisiable
      this.addOredit = "add" //修改标记

      this.$api
        .getmenu({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.treeData = response.data
        })
        .catch((err) => {
          alert(err)
        })

      //获取菜单的列
      this.$api
        .roleList({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.form.role_name = response.data
        })
        .catch((err) => {
          alert(err)
        })
    },
    onSubmit() {
      var arr
      if (
        !(arr = Object.keys(this.form).filter((v) => !this.form[v])).length <= 0
      ) {
        this.$notify.error({
          title: "温馨提示",
          message: "请把信息填写完毕在提交的哦"
        })
      } else {
        //添加用户管理员
        this.$api
          .addUser({
            token: window.localStorage.getItem("token"),
            userName: window.localStorage.getItem("userName"),
            form: this.form,
            tag: this.addOredit,
            userId: this.userId
          })
          .then((res) => {
            let response = JSON.parse(res)
            if (response.code == 200) {
              this.$message({
                message: response.msg,
                duration: 1300,
                type: "success",
                onClose: () => {
                  location.reload()
                }
              })
            } else {
              this.$notify.error({
                title: "温馨提示",
                message: response.msg
              })
            }
          })
          .catch((err) => {
            alert(err)
          })
      }
    },
    delOne(index, row) {
      this.$confirm("此操作将永久删除该管理员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //删除后台管理员
          this.$api
            .deluser({
              token: window.localStorage.getItem("token"),
              id: row.id
            })
            .then((res) => {
              let response = JSON.parse(res)
              if (response.code == 200) {
                this.$message({
                  message: response.msg,
                  duration: 1300,
                  type: "success",
                  onClose: () => {
                    location.reload()
                  }
                })
              }
            }).catch((err)=>{
              alert(err);
            })
        })
        .catch(() => {})
    },
    editOne(index, row) {
      //获取当前的user进行
      this.dialogVisiable = !this.dialogVisiable
      this.addOredit = "edit" //修改标记
      ;(this.userId = row.id),


        this.$api.oneuser({
          token: window.localStorage.getItem("token"),
          userId: row.id
        }).then((res) => {
          let response = JSON.parse(res);
          this.form.userName = response.data.userName
          this.form.userId = response.data.role_id
          this.form.phone = response.data.phone
          this.form.xingming = response.data.name
          this.form.sort = response.data.sort
          this.form.radioTag = response.data.salt_phone
          //下拉菜单的赋值
          this.form.role_name = response.roleMenu
        }) .catch((err) => {
           alert(err)
          })

    },
    //单选框的修改
    sortChange(e) {
      this.form.radioTag = e
    }
  },
  mounted() {
    //获取角色的列表
      this.$api.userList({
         token: window.localStorage.getItem("token")
      }).then((res) => {
        let response = JSON.parse(res)
        this.tableData = response.data
      })
  }
}
</script>

<style>
.listBox {
  margin-top: 20px;
}
</style>