<template>
  <div>
    <!-- 点击添加的按钮 -->
    <div class="addBtn">
      <el-button type="primary" icon="el-icon-plus" @click="addBtn"
        >新增</el-button
      >
    </div>
    <!-- 角色的列表 -->
    <div class="listBox">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="id" label="角色ID" width="180">
        </el-table-column>
        <el-table-column prop="role_name" v-model="role_name" label="角色名称">
        </el-table-column>
        <el-table-column prop="add_time" label="日期"> </el-table-column>
        <el-table-column prop="address" label="编辑">
          <template slot-scope="scope">
            <el-button type="text" @click="editOne(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button type="text" v-if="scope.row.id == 1" disabled @click="delOne(scope.$index, scope.row)"
              >删除</el-button
            >
            <el-button type="text" v-else @click="delOne(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增的模态框 -->
    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      title="新增角色"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="角色名称">
          <el-input v-model="role_name"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-tree
            :data="treeData"
            show-checkbox
            ref="tree"
            node-key="id"
            :default-expanded-keys="[]"
            :default-checked-keys= chooseKey
            :props="defaultProps"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiable = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisiable: false,
      defaultProps: "",
      role_name: "",
      treeData: [],
      roleId:'',
      chooseKey:[],   //当前选中
      addOredit:'',   //模态框提交的【增加：add,修改：edit】
    };
  },
  methods: {
    addBtn() {
      this.dialogVisiable = !this.dialogVisiable;
      this.addOredit = 'add';   //修改标记
        //获取树形菜单列表
        this.$api.getmenu({
          token: window.localStorage.getItem("token")
        }).then((res)=>{
          let response = JSON.parse(res);
          this.treeData = response.data;
        }).catch(err=>{
          alert(err);
        });
    },
    onSubmit() {
      const choosKey = this.$refs.tree.getCheckedKeys();
      if (this.role_name == "") {
        this.$notify.error({
          title: "温馨提示",
          message: "请把信息填写完毕在提交的哦",
        });
      } else {
        //增加角色的列表
        this.$api.addRole({
          token: window.localStorage.getItem("token"),
          keyArr: choosKey,
          roleName: this.role_name.replace(/\s*/g, ""),
          tag:this.addOredit,
          roleId:this.roleId
        }).then(res=>{
            let response = JSON.parse(res);
            if (response.code == 200) {
                this.$message({
                  message: response.msg,
                  duration: 1300,
                  type: "success",
                  onClose: () => {
                    location.reload();
                  },
                });
              }
        }).catch(err=>{
          alert(err);
        });
      }
    },
    delOne(index, row) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //删除操作
          this.$api.delRole({
            token: window.localStorage.getItem("token"),
            id: row.id,
          }).then(res=>{
              let response = JSON.parse(res);
              if (response.code == 200) {
                this.$message({
                  message: response.msg,
                  duration: 1300,
                  type: "success",
                  onClose: () => {
                    location.reload();
                  },
                });
              }
          }).catch(err=>{
            alert(err);
          });
        })
        .catch(() => {});
    },
    editOne(index, row){
      this.dialogVisiable = !this.dialogVisiable;
      this.addOredit = 'edit';    //修改标记
      this.roleId = row.id,

      this.$api.userMenu({
        token: window.localStorage.getItem("token"),
        roleId:row.id,
        tag:'edit'
      }).then(res=>{
          let response = JSON.parse(res);
          this.treeData = response.menuData;
          this.chooseKey = response.menuArr;
          this.role_name = response.data[0].role_name;
      }).catch(err=>{
        alert(err);
      });

    }
  },
  mounted() {
    //获取角色的列表
    this.$api.roleList({
      token: window.localStorage.getItem("token"),
    }).then(res=>{
      let response = JSON.parse(res);
      this.tableData = response.data;
    }).catch(err=>{
      alert(err);
    });
  },
};
</script>

<style>
.listBox {
  margin-top: 20px;
}
</style>