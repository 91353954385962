<template>
  <div class="">
    <!-- 上边的工具栏 -->
    <div class="toolsBox">
      <div class="sortTag">
        <el-tag
          v-for="(item, index) in sortList"
          :key="index"
          @click="sortBtn(item.id)"
        >
          {{ item.sort_name }}
        </el-tag>
      </div>
      <div class="addBtn">
        <el-button
          type="primary"
          icon="el-icon-upload"
          size="small"
          @click="dialogFormVisible = true"
        >
          上传图片
        </el-button>
      </div>
    </div>
    <!-- 下边的图片显示区域 -->
    <div class="imglistBox" v-loading="loading">
      <div class="itemOnebox" v-for="(item, index) in list" :key="index">
        <div class="imgTop">
          <el-image
            :src="item.img"
            alt=""
            lazy
          ></el-image>
          <div class="tagBox">
            <el-tag size="mini" v-if="item.sort == 1">动漫</el-tag>
            <el-tag size="mini" v-if="item.sort == 2">游戏</el-tag>
            <el-tag size="mini" v-if="item.sort == 3">美女</el-tag>
            <el-tag size="mini" v-if="item.sort == 4">型男</el-tag>
            <el-tag size="mini" v-if="item.sort == 5">手绘</el-tag>
            <el-tag size="mini" v-if="item.sort == 6">潮图</el-tag>
            <el-tag size="mini" v-if="item.sort == 7">城市</el-tag>
            <el-tag size="mini" v-if="item.sort == 8">机车</el-tag>
            <el-tag size="mini" v-if="item.sort == 9">情感</el-tag>
            <el-tag size="mini" v-if="item.sort == 10">动物</el-tag>
            <el-tag size="mini" v-if="item.sort == 11">风景</el-tag>
            <el-tag size="mini" v-if="item.sort == 12">文字</el-tag>
          </div>
          <div class="zhezhaoBox">
            <span>口令：{{ item.kouling }}</span>
          </div>
        </div>
        <div class="btnBox">
          <el-button type="primary" size="small" plain>修改</el-button>
          <el-button type="success" size="small" plain @click="delImg(item)">
            删除
          </el-button>
        </div>
      </div>
    </div>

    <!-- 新增图片 -->
    <el-dialog title="上传图片" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="图片分类">
          <el-select v-model="form.region" placeholder="请选择图片的分类">
            <el-option
              v-for="(item, index) in sortList"
              :key="index"
              :label="item.sort_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="口令">
          <el-input v-model="form.kouling" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess2"
            :limit="10"
            :headers="token"
            :on-remove="handleRemove2"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input
            type="file"
            id="inputFile"
            style="display: none"
            multiple="multiple"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBtn()">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      loading: true,
      list: [],
      token: {
        token: localStorage.getItem("token")
      },
      fileList: [],
      dialogFormVisible: false,
      form: {
        region: "",
        kouling:'',
        imgList:[]
      },
      sortList: []
    }
  },
  mounted() {
    this.getImg(),
      //获取图片分类的
      this.getSort()
  },
  methods: {
    //获取所有的图片
    getImg() {
      this.$api
        .imgList({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.list = response.data
          this.loading = false
        })
        .catch((err) => {
          alert(err)
        })
    },
    //获取分类的列表
    getSort() {
      this.$api
        .imgSort({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.sortList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    //删除图片
    delImg(item) {
      this.$confirm("删除该图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //删除图片
          this.$api
            .delImg({
              token: window.localStorage.getItem("token"),
              imgName: item.img,
              imgId: item.id
            })
            .then((res) => {
              let response = JSON.parse(res)
              if (response.code == 200) {
                this.$notify({
                  title: "成功",
                  message: response.msg,
                  duration: 1500,
                  type: "success",
                  onClose: () => {
                    this.getImg()
                  }
                })
              } else if (response.code == 201) {
                this.$notify.error({
                  title: "失败",
                  message: response.msg
                })
              }
            })
            .catch((err) => {
              alert(err)
            })
        })
        .catch(() => {})
    },
    //上传多图进行list添加操作
    handleAvatarSuccess2(res, file) {
      if (res.code == 200) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success"
        })
        //追加到数组中
        this.form.imgList.push(res.imgUrl)
      } else {
        this.$notify.error({
          title: "失败",
          message: res.msg
        })
      }
    },
    //内容多图删除的操作
    handleRemove2(file, fileList) {
      console.log(file, "打印")
      this.$api
        .delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        })
        .then((res) => {
          let response = JSON.parse(res)
          if (response.code == 200) {
            this.$notify({
              title: "成功",
              message: response.msg,
              type: "success",
              duration: 1500
            })
            //循环找出指定的key进行移除数组操作
            for (var i = 0; i < this.form.imgList.length; i++) {
              if (this.form.imgList[i] == file.response.imgUrl) {
                this.form.imgList.splice(i, 1)
              }
            }
          } else {
            this.$notify.error({
              title: "失败",
              message: response.msg
            })
          }
        })
        .catch((err) => {
          alert(err)
        })
    },
    //根据ID查询分类的图片
    sortBtn(e) {
      this.loading = true
      this.$api
        .oneSort({
          token: window.localStorage.getItem("token"),
          sortId: e
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.list = response.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    //点击提交的按钮
    submitBtn(){
      if(this.form.region != '' && this.form.imgList.length>0){
        this.$api.imgtoDb({
           token: window.localStorage.getItem("token"),
           form:this.form
        }).then((res) => {
          let response = JSON.parse(res);
          this.$notify({
                title: "成功",
                message: response.msg,
                duration: 1500,
                type: "success",
                onClose: () => {
                  this.getImg()
                }
              })
        }).catch((error) => {
          console.log(error);
        })
      }else{
        this.$notify.error({
            title: "提示",
            message: '请补全信息哟'
          })
      }
    }
  }
}
</script>

<style lang="scss">
.imglistBox {
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  justify-content: space-between;
  .itemOnebox {
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
    width: 120px;
    height: 220px;
    .imgTop {
      display: flex;
      position: relative;
      height: 180px;
      width: 100%;
      .tagBox {
        position: absolute;
        top: -3px;
        right: 0px;
        .el-tag {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
      .zhezhaoBox {
        position: absolute;
        bottom: 0px;
        background: #000;
        width: 120px;
        height: 30px;
        opacity: 0.4;
        color: white;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        z-index: 1;
        text {
          z-index: 999;
        }
      }
      img {
        width: 120px;
        height: 180px;
        border-radius: 6px;
      }
    }
    .btnBox {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
    }
  }
}
.imglistBox:after {
  content: "";
  display: block;
  width: 140px;
  height: 100px;
}
.toolsBox {
  display: flex;
  padding: 20px 0px;
  .sortTag {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    span {
      margin-left: 10px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .addBtn {
    display: flex;
    width: 150px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6px;
    button {
      height: 40px;
    }
  }
}
</style>