<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      title="添加专题"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="专题名称">
          <el-input v-model="form.zt_name"></el-input>
        </el-form-item>
        <!-- 单图上传 -->
        <el-form-item label="封面图">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess1"
            :limit="1"
            :headers="token"
            :on-remove="handleRemove1"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input
            type="file"
            id="inputFile"
            style="display: none"
            multiple="multiple"
          />
        </el-form-item>
        <!-- 多图上传 -->
        <el-form-item label="内容">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess2"
            :limit="10"
            :headers="token"
            :on-remove="handleRemove2"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input
            type="file"
            id="inputFile"
            style="display: none"
            multiple="multiple"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiable = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      token: {
        token: localStorage.getItem("token")
      },
      form: {
        zt_name: "",
        zt_cover: null, //封面图
        zt_content: [] //内容多图形式
      },
      fileList: []
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$message("取消")
    },
    //上传一张也就是 封面图片的操作
    handleAvatarSuccess1(res, file) {
      if (res.code == 200) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success"
        })
        this.form.zt_cover = res.imgUrl
      } else {
        this.$notify.error({
          title: "失败",
          message: res.msg
        })
      }
    },
    //上传多图进行list添加操作
    handleAvatarSuccess2(res, file) {
      if (res.code == 200) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success"
        })
        //追加到数组中
        this.form.zt_content.push(res.imgUrl)
      } else {
        this.$notify.error({
          title: "失败",
          message: res.msg
        })
      }
    },
    onSubmit() {
      if (
        this.form.zt_name != "" &&
        this.form.zt_cover != "" &&
        this.form.zt_content.length > 0
      ) {
        this.$api
          .zhuantiAdd({
            token: window.localStorage.getItem("token"),
            form: this.form
          })
          .then((res) => {
            let response = JSON.parse(res)
            if (response.code == 200) {
              this.dialogVisiable = false
              this.$notify({
                title: "成功",
                message: response.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  location.reload()
                }
              })
            } else {
              this.$notify.error({
                title: "失败",
                message: response.msg
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.$notify.error({
          title: "提示",
          message: "请先填写信息在提交哟！"
        })
      }
    },
    //主图的删除 清空操作
    handleRemove1(file, fileList) {
      this.$api
        .delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        })
        .then((res) => {
          let response = JSON.parse(res)
          if (response.code == 200) {
            this.$notify({
              title: "成功",
              message: response.msg,
              type: "success",
              duration: 1500
            })
            //把变量的值 清空
            this.form.zt_cover = ""
          } else {
            this.$notify.error({
              title: "失败",
              message: response.msg
            })
          }
        })
        .catch((err) => {
          alert(err)
        })
    },

    //内容多图删除的操作
    handleRemove2(file, fileList) {
      console.log(file, "打印")
      this.$api
        .delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        })
        .then((res) => {
          let response = JSON.parse(res)
          if (response.code == 200) {
            this.$notify({
              title: "成功",
              message: response.msg,
              type: "success",
              duration: 1500
            })
            //循环找出指定的key进行移除数组操作
            for (var i = 0; i < this.form.zt_content.length; i++) {
              if (this.form.zt_content[i] == file.response.imgUrl) {
                this.form.zt_content.splice(i, 1)
              }
            }
          } else {
            this.$notify.error({
              title: "失败",
              message: response.msg
            })
          }
        })
        .catch((err) => {
          alert(err)
        })
    }
  },
  computed: {
    dialogVisiable: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit("update:visible", val)
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
</style>