<template>
  <div>
    <!-- 点击添加的按钮 -->
    <div class="addBtn">
      <el-button type="primary" icon="el-icon-plus" @click="addBtn">
        新增
      </el-button>
    </div>
    <el-table :data="tableData" v-loading="loading" border style="width: 100%">
      <el-table-column
        prop="id"
        label="专题ID"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="zt_name"
        align="center"
        width="200"
        label="专题名称"
      ></el-table-column>
      <el-table-column align="center" label="专题封面图" width="200px">
        <template slot-scope="scope">
          <el-image
            style="width: 150px; height: 80px; border-radius: 6px"
            :src="scope.row.zt_cover"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column property="images" label="图片内容" align="center">
        <template slot-scope="scope">
          <el-image
            v-for="(item, index) in scope.row.zt_content"
            @click="changeImg(scope.row)"
            :key="index"
            style="
              width: 50px;
              height: 90px;
              margin-left: 6px;
              border-radius: 5px;
            "
            :src="item"
            :preview-src-list="srcList"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加item的组件 -->
    <addItemDialog :visible.sync="addDialog"></addItemDialog>
  </div>
</template>

<script>
import addItemDialog from "./children/add"
export default {
  components: {
    addItemDialog
  },
  data() {
    return {
      loading: true,
      srcList: [], //存在点击预览图的数组
      tableData: [],
      addDialog: false //add增加的模态框子组件
    }
  },
  mounted() {
    this.getImg()
  },
  methods: {
    getImg() {
      //获取分类的列表
      this.$api
        .zhuantiList({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.tableData = response.data
          this.loading = false
        })
        .catch((err) => {
          alert(err)
        })
    },
    /**
     * 点击改变预览的图片的数组
     */
    changeImg(item) {
      this.srcList = item.zt_content
    },
    addBtn() {
      this.addDialog = !this.addDialog
    },
    /**
     * 删除图片
     */
    handleDelete(index, item) {
      this.$confirm("此操作将永久删除该专题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api
            .zhuantiDel({
              token: window.localStorage.getItem("token"),
              itemId: item.id
            })
            .then((res) => {
              let response = JSON.parse(res)
              if (response.code == 200) {
                this.$notify({
                  title: "成功",
                  message: response.msg,
                  type: "success",
                  offset:100,
                  duration: 1500,
                  onClose: () => {
                    location.reload()
                  }
                })
              } else {
                this.$notify.error({
                  title: "失败",
                  message: response.msg
                })
              }
            })
            .catch((err) => {
              alert(err)
            })
        })
        .catch(() => {
          console.log("取消了")
        })
    }
  }
}
</script>

<style scoped>
.addBtn {
  margin-bottom: 20px;
}
</style>>