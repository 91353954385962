<template>
  <div>
    <el-table
    :data="tableData"
    v-loading="loading"
    border
    style="width: 100%"
    >
    <el-table-column
      prop="id"
      label="id"
      width="180">
    </el-table-column>
    <el-table-column
      prop="sort_name"
      label="分类名字"
      align="center"
      width="180">
    </el-table-column>
    <el-table-column
      prop="xuhao"
      align="center"
      label="排序">
    </el-table-column>
    <el-table-column
      align="center"
      label="背景图片">
        <template slot-scope="scope">
            <el-image 
                style="width: 150px; height: 80px;border-radius:6px ;"
                :src="scope.row.bg_img">
            </el-image>
        </template>
    </el-table-column>
     <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.row)">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 修改组件 -->
  <editItemDialog 
    :visible.sync="editDialog"
		v-bind:itemInfo="oneObj"
		></editItemDialog>
  </div>
</template>

<script>
import editItemDialog from "./children/edit";
export default {
  components: {
    editItemDialog
  },
  props: [],
  data() {
    return {
      loading:true,
      editDialog:false,
      tableData: [],
      oneObj:{}
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted(e) {
    console.log();
    this.getList();
  },
  methods: {
    /**
     * 获取分类的列表
     */
    getList() {

        //获取分类的列表
        this.$api.sortlist({
          token: window.localStorage.getItem("token"),
        }).then((res)=>{
          let response = JSON.parse(res);
          this.tableData= response.data;
          this.loading = false;
        }).catch((err)=>{
          alert(err);
        })
    },

    /**
     * 编辑
     */
    handleEdit(row){
      this.oneObj = JSON.parse(JSON.stringify(row));
			this.editDialog = !this.editDialog;
    }
  },
};
</script>

<style>

</style>