<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      title="添加banner轮播图"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="排序">
          <el-input v-model="form.xuhao"></el-input>
        </el-form-item>
        <!-- 加壳的照片 -->
        <el-form-item label="加壳图片">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess1"
            :limit="1"
            :headers="token"
            :on-remove="handleRemove1"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input
            type="file"
            id="inputFile"
            style="display: none"
            multiple="multiple"
          />
        </el-form-item>
        <!-- 多图上传 -->
        <el-form-item label="原图">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess2"
            :limit="1"
            :headers="token"
            :on-remove="handleRemove2"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input
            type="file"
            id="inputFile"
            style="display: none"
            multiple="multiple"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiable = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      token: {
        token: localStorage.getItem("token")
      },
      form: {
        xuhao:'',
        ke_img: '', //加壳的图片
        img: '' //不加壳的照片
      },
      fileList: []
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$message("取消")
    },
    //上传一张也就是 封面图片的操作
    handleAvatarSuccess1(res, file) {
      if (res.code == 200) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success"
        })
        this.form.ke_img = res.imgUrl
      } else {
        this.$notify.error({
          title: "失败",
          message: res.msg
        })
      }
    },
  
    handleAvatarSuccess2(res, file) {
      if (res.code == 200) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success"
        })
        this.form.img = res.imgUrl
      } else {
        this.$notify.error({
          title: "失败",
          message: res.msg
        })
      }
    },
    onSubmit() {
      if (this.form.img != '' &&
          this.form.ke_img != '' &&
          this.form.xuhao != ''
      ) {
        this.$api
          .bannerAdd({
            token: window.localStorage.getItem("token"),
            form: this.form
          })
          .then((res) => {
            let response = JSON.parse(res)
            if (response.code == 200) {
              this.dialogVisiable = false
              this.$notify({
                title: "成功",
                message: response.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  location.reload()
                }
              })
            } else {
              this.$notify.error({
                title: "失败",
                message: response.msg
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.$notify.error({
          title: "提示",
          message: "请先填写信息在提交哟！"
        })
      }
    },
    //主图的删除 清空操作
    handleRemove1(file, fileList) {
      this.$api
        .delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        })
        .then((res) => {
          let response = JSON.parse(res)
          if (response.code == 200) {
            this.$notify({
              title: "成功",
              message: response.msg,
              type: "success",
              duration: 1500
            })
            //把变量的值 清空
            this.form.ke_img = ""
          } else {
            this.$notify.error({
              title: "失败",
              message: response.msg
            })
          }
        })
        .catch((err) => {
          alert(err)
        })
    },

    //图二删除
    handleRemove2(file, fileList) {
      this.$api
        .delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        })
        .then((res) => {
          let response = JSON.parse(res)
          if (response.code == 200) {
            this.$notify({
              title: "成功",
              message: response.msg,
              type: "success",
              duration: 1500
            })
            //把变量的值 清空
            this.form.img = ""
          } else {
            this.$notify.error({
              title: "失败",
              message: response.msg
            })
          }
        })
        .catch((err) => {
          alert(err)
        })
    }
  },
  computed: {
    dialogVisiable: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit("update:visible", val)
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
</style>