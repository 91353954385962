<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisiable"
      :close-on-click-modal="false"
      title="分类编辑"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分类ID">
          <el-input v-model="itemInfo.id" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input v-model="itemInfo.sort_name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="itemInfo.xuhao"></el-input>
        </el-form-item>
        <el-form-item label="封面图">
          <el-upload
            action="https://bizhi.siyu128.cn/api/tools/qiniuImg"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :limit="1"
            :headers="token"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <input type="file" id="inputFile" style="display: none; " multiple="multiple">
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiable = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      itemOne: '',
      token: {
        token: localStorage.getItem("token")
      },
      form: {},
      fileList: []
    }
  },
  props: {
    itemInfo: {
      type: Object,
      required: true
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$message("取消")
    },
    handleAvatarSuccess(res, file) {
      //上传图片的操作
      if(res.code == 200){
        this.$notify({
          title: '成功',
          message: res.msg,
          type: 'success'
        });
        this.imageUrl = URL.createObjectURL(file.raw)
        this.itemInfo.bg_img = res.imgUrl
      }else{
        this.$notify.error({
          title: '失败',
          message: res.msg
        });
      }
    },
    onSubmit() {
      this.$api.editSort({
        token: window.localStorage.getItem("token"),
        form:this.itemInfo
      }).then((res) => {
         let response = JSON.parse(res);
            if(response.code == 200){
              this.dialogVisiable = false;
              this.$notify({
                title: '成功',
                message: response.msg,
                type: 'success',
                duration:1500,
                onClose: () => {
                  location.reload();
                }
              })
            }else{
              this.$notify.error({
                title: '失败',
                message: response.msg
              });
            }
      }).catch((error)=>{
        console.log(error);
      })
    },
    handleRemove(file, fileList) {
      console.log(file);
      //删除图片的操作
     this.$api.delqiniuImg({
          token: window.localStorage.getItem("token"),
          imgName: file.response.imgUrl
        }).then((res)=>{
            let response = JSON.parse(res);
            if(response.code == 200){
              this.$notify({
                title: '成功',
                message: response.msg,
                type: 'success',
                duration: 1500
              });
            }else{
              this.$notify.error({
                title: '失败',
                message: response.msg
              });
            }
        }).catch((err)=>{
          alert(err);
        })
    }
  },
  computed: {
    dialogVisiable: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit("update:visible", val)
      }
    }
  },
  mounted() {
    this.itemOne = this.itemInfo
  }
}
</script>

<style scoped>
</style>