<template>
  <div>
    <!-- 点击添加的按钮 -->
    <div class="addBtn">
      <el-button type="primary" icon="el-icon-plus" @click="addBtn">
        新增
      </el-button>
    </div>
    <el-table :data="tableData" v-loading="loading" border style="width: 100%">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column align="center" label="加手机壳图片" width="350px">
        <template slot-scope="scope">
          <el-image
            style="width: 80px; height: 150px; border-radius: 6px"
            :src="scope.row.ke_img"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column property="images" label="原图片" align="center">
         <template slot-scope="scope">
          <el-image
            style="width: 80px; height: 150px; border-radius: 6px"
            :src="scope.row.img"
          ></el-image>
        </template>
      </el-table-column>
        <el-table-column
        prop="id"
        label="排序"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
           <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加item的组件 -->
    <addItemDialog :visible.sync="addDialog"></addItemDialog>
  </div>
</template>

<script>
import addItemDialog from "./children/add"
export default {
  components: {
    addItemDialog
  },
  data() {
    return {
      form:{

      },
      loading: true,
      srcList: [], //存在点击预览图的数组
      tableData: [],
      addDialog: false //add增加的模态框子组件
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$api
        .bannerList({
          token: window.localStorage.getItem("token")
        })
        .then((res) => {
          let response = JSON.parse(res)
          this.tableData = response.data
          this.loading = false
        })
        .catch((err) => {
          alert(err)
        })
    },
    /**
     * 点击改变预览的图片的数组
     */
    changeImg(item) {
      this.srcList = item.zt_content
    },
    addBtn() {
      this.addDialog = !this.addDialog
    },
    /**
     * 删除item操作
     */
    handleDelete(index, item) {
      this.$confirm("此操作将永久删除该专题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api
            .bannerDel({
              token: window.localStorage.getItem("token"),
              itemId: item.id
            })
            .then((res) => {
              let response = JSON.parse(res)
              if (response.code == 200) {
                this.$notify({
                  title: "成功",
                  message: response.msg,
                  type: "success",
                  offset:100,
                  duration: 1500,
                  onClose: () => {
                    location.reload()
                  }
                })
              } else {
                this.$notify.error({
                  title: "失败",
                  message: response.msg
                })
              }
            })
            .catch((err) => {
              alert(err)
            })
        })
        .catch(() => {
          console.log("取消了")
        })
    }
  }
}
</script>

<style scoped>
.addBtn {
  margin-bottom: 20px;
}
</style>>